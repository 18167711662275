<template>
    <div class="stepsdemo-content">
        <Card>
           <template v-slot:title>
                {{$t('Datos de la Propiedad')}}
            </template>
            <template v-slot:subtitle>
                {{$t('Fill out the quote information ')}}
            </template>
            <template v-slot:content>
				
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-12 p-md-6">
						<label>{{$t('Localidad')}}</label>
						<div v-if="i18n.locale() == 'es'">
							{{formData.datos.localidad.nombre}}
						</div>
						<div v-if="i18n.locale() == 'en'">
							{{formData.datos.localidad.name}}
						</div>
					</div>
					<div class="p-field p-col-12 p-md-6">
						<label>{{$t('Beneficiario')}}</label>
						<Textarea v-model="propiedad.beneficiario" rows="1"/>
					</div>
					<div class="p-field p-col-12 p-md-6">
						<label>{{$t('Dirección')}}</label>
						<Textarea v-model="propiedad.direccion" rows="4"/>
                        <small v-show="validationErrors.direccion && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-12 p-md-6">
						<label>{{$t('Comentario')}}</label>
						<Textarea v-model="propiedad.comentario" rows="4"/>
                        <small v-show="validationErrors.comentario && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					
                </div>
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-6 p-md-6">
						<label v-if="formData.datos.tipocliente.code==1">{{$t('Titulo de propiedad')}}</label>
						<label v-else>{{$t('Informe Cope')}}</label>
						<FileUpload :chooseLabel="$t('Subir PDF')" uploadLabel="Cargar" mode="basic" :name="img2" :url="url.api+'upload.php'"  accept="application/pdf" :maxFileSize="4000000" @upload="myUploader(2,img2)" :auto="true"/>
                        <small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small><br>
                        <small v-show="validationErrors.titulo && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-6 p-md-6">
						<label v-if="formData.datos.tipocliente.code==1">{{$t('Evaluación de la propiedad (mínimo 6 meses)')}}</label>
						<label v-else>{{$t('Estados financieros - Últimos tres años')}}</label>
						<FileUpload :chooseLabel="$t('Subir PDF')" uploadLabel="Cargar" mode="basic" :name="img1" :url="url.api+'upload.php'"  accept="application/pdf" :maxFileSize="4000000" @upload="myUploader(1,img1)" :auto="true"/>
                        <small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small><br>
                        <small v-show="validationErrors.evaluacion && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-6 p-md-6">
						<img style="width: 50px;" src="images/pdf.jpg" v-if="propiedad.titulo" @click="Ver(propiedad.evaluacion)"/>
					</div>
					<div class="p-field p-col-6 p-md-6">
						<img style="width: 50px;" src="images/pdf.jpg" v-if="propiedad.evaluacion" @click="Ver(propiedad.evaluacion)"/>
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button :disabled ="$store.state.loading" :label="$t('Anterior')" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button :disabled ="$store.state.loading" :label="$t('Siguiente')" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import API from "@/service/API";
const Consulta = new API('Cotizacion','Incendio');
import { useI18nPlugin } from '@unify/vuex-i18n';
import Token from "uuid-token-generator";
import url from "@/service/_URL";

export default {
    props: {
        formData: Object
    },
    data () {
        return {			
            submitted: false,
            validationErrors: {},
			tipos: null,
			propiedad: {
				evaluacion: null,
				titulo: null,
				direccion: null,
				localidad: null,
				comentario: null,
				beneficiario: null,
			},
			localidades: [],
            size: '40vw',
			img1: null,
			img2: null,
        }
    },
	created() {
        if (this.$store.state.mobile){this.size = '80vw';}
		this.i18n = useI18nPlugin();
        this.url = url;
		Consulta.Ini().then(result => {
			if (result.consult.localidades){
				this.localidades = result.consult.localidades;
			}
		}); 
		const tokgen = new Token(256);
		this.img1 = tokgen.generate();
		this.img2 = tokgen.generate();
	},
    methods: {
        Ver(document){
            window.open(url.upload+document+'.pdf', '_blank');
		},
        nextPage() {
            this.submitted = true;
            if (this.validateForm() ) {
				this.propiedad.localidad = this.formData.datos.localidad;
                this.$emit('next-page', {formData: {propiedad: this.propiedad}, pageIndex: 1});
            }
        },
        prevPage() {
            this.$emit('prev-page', {pageIndex: 1});
        },
		myUploader(caso,img) {
			Consulta.Procesar('RevPDF',{
				foto: img,
			}).then(response => {
				if(response.result){
					if(caso == 1){this.propiedad.evaluacion = img;}
					if(caso == 2){this.propiedad.titulo = img;}
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
                const tokgen = new Token(256);
                this.img = tokgen.generate();
			});
		},
        validateForm() {
            if (this.propiedad.direccion==null)
               this.validationErrors['direccion'] = true;
            else
               delete this.validationErrors['direccion'];
            return !Object.keys(this.validationErrors).length;
        }
    }
}
</script>